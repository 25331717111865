import { homePath, linkAdminPath, Modules } from '@routes';
import { AppHeaderConfigPayload } from 'admin-portal-shared-services';
import { t } from 'i18next';

type TAppHeaderConfig = (pageName: Modules) => AppHeaderConfigPayload;

export const defaultAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: ' ',
  vendorSelect: false,
  countrySelect: false,
  countryOptions: [],
  breadcrumbConfig: undefined,
};

export const appHeaderConfig: TAppHeaderConfig = (pageName) => {
  switch (pageName) {
    case 'ORDER_FORECAST':
      return {
        pageTitle: ' ',
        breadcrumbConfig: {
          homePath: linkAdminPath,
          items: [
            {
              label: t('forecast:title'),
              path: homePath,
              isCurrentPage: true,
            },
          ],
        },
      };
    case 'CREATE_FORECAST':
      return {
        pageTitle: ' ',
        breadcrumbConfig: {
          homePath: linkAdminPath,
          items: [
            {
              label: t('forecast:title'),
              path: homePath,
              isCurrentPage: false,
            },
            {
              label: t('createForecast:title'),
              path: homePath,
              isCurrentPage: true,
            },
          ],
        },
      };
    default:
      return defaultAppHeaderConfig;
  }
};
