import { Error403 } from '@hexa-ui/components';
import { linkAdminPath } from '@routes';
import { useHasPermission } from 'admin-portal-shared-services';
import { t } from 'i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: React.ReactElement;
  permission: string | string[];
  isEnabled?: boolean;
}

export const ProtectedRoute = ({ isEnabled = false, children, permission }: Props) => {
  const navigate = useNavigate();
  const hasUserPermission = useHasPermission(permission, 'OR');

  return isEnabled && hasUserPermission ? (
    <>{children}</>
  ) : (
    <div style={{ height: '85vh' }}>
      <Error403
        header={t('common:accessDenied.title')}
        description={t('common:accessDenied.subtitle')}
        buttonText={t('common:accessDenied.buttonText')}
        onButtonClick={() => navigate(linkAdminPath)}
      />
    </div>
  );
};
