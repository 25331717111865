import { useQuery } from '@tanstack/react-query';
import { useAppHeader, usePermissionService } from 'admin-portal-shared-services';
import { useApi } from '../api';

interface ISidebarItem {
  color: string;
  icon: string;
  id: string;
  name: string;
  path: string;
  permission: string;
  position: number;
  size: string;
  subTitleCard: string;
  title: string;
}

export type ISidebarResponse = {
  content: ISidebarItem[];
};

export const useSidebarService = () => {
  const { get } = useApi();
  const [appHeaderConfig] = useAppHeader();
  const { selectedCountry } = appHeaderConfig;
  const permissionService = usePermissionService();

  const { data = [], isSuccess } = useQuery(
    ['sidebar', selectedCountry],
    async () => {
      const response = await get<ISidebarResponse>({
        url: 'api/modern-trade-management/mfe',
        config: {
          headers: {
            requestTraceId: 'sidebar',
            country: selectedCountry,
          },
        },
      });

      return response.data.content.filter((res) =>
        res.id == 'common'
          ? permissionService.hasScopesToApp(`${res.permission}`)
          : permissionService.hasOneOfScopes(`${res.permission}`)
      );
    },
    {
      enabled: !!selectedCountry,
    }
  );

  return { data, isSuccess };
};
