/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import axios from 'axios';
import { IGet, IRequest, IRequestFile } from './api.types';

export const useApi = () => {
  const auth = useAuthenticationService();
  const token = auth.getAuthHeader();
  const { user_country: userCountry } = auth.getUserCountryAndLanguage();
  const country = userCountry ?? '';

  const axiosInstance = axios.create({
    baseURL: window.origin,
    headers: {
      Authorization: token,
    },
  });

  auth.enhancedAxios(axiosInstance, {
    headers: [
      {
        country,
      },
    ],
  });

  const api = {
    get: function get<T>(props: IGet): Promise<any> {
      return axiosInstance.get<T>(props.url, props.config);
    },
    post: function post({ url, body, config }: IRequest): Promise<any> {
      return axiosInstance.post(url, body, config);
    },
    postFormData: function postFormData({ url, data, config }: IRequestFile): Promise<any> {
      return axiosInstance.post(url, data, {
        ...config,
        headers: {
          ...config?.headers,
          form: 'multipart/form-data',
          accept: 'application/json',
        },
      });
    },
  };

  return api;
};
