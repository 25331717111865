import { Grid } from '@hexa-ui/components';
import { useDynamicSidebar } from '@hooks';
import { appHeaderConfig as appHeaderConfigUtil, defaultAppHeaderConfig } from '@utils';
import { useAppHeader, useAuthenticationService } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import * as Styles from './PageWrapper.styles';
import { PageWrapperProps } from './PageWrapper.types';

export const PageWrapper = ({
  children,
  pageName,
  dataTestId,
  showBreadcrumb = false,
  showCountrySelect = false,
  showVendorSelect = false,
}: PageWrapperProps) => {
  const [, setAppHeaderConfig] = useAppHeader();
  const authentication = useAuthenticationService();

  useEffect(() => {
    const setBreadcrumb = async () => {
      let appHeaderConfig = {
        ...defaultAppHeaderConfig,
        defaultCountry: authentication.getCountryB2C(),
        countryOptions: authentication.getSupportedCountries(),
      };

      if (showVendorSelect) {
        appHeaderConfig = {
          ...appHeaderConfig,
          vendorSelect: true,
        };
      }

      if (showCountrySelect) {
        appHeaderConfig = {
          ...appHeaderConfig,
          countrySelect: true,
        };
      }

      if (showBreadcrumb) {
        appHeaderConfig = {
          ...appHeaderConfig,
          ...appHeaderConfigUtil(pageName),
        };
      }

      setAppHeaderConfig(appHeaderConfig);
    };

    setBreadcrumb();
  }, [
    pageName,
    setAppHeaderConfig,
    showBreadcrumb,
    authentication,
    showVendorSelect,
    showCountrySelect,
  ]);

  useDynamicSidebar();

  return (
    <Grid.Container sidebar type="fluid" data-testid={dataTestId}>
      <Styles.Content>{children}</Styles.Content>
    </Grid.Container>
  );
};
