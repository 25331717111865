import { useSidebarService } from '@api';
import * as icons from '@hexa-ui/icons';
import { SidebarItem, useSidebar } from 'admin-portal-shared-services';
import { createElement, useEffect, useState } from 'react';

export type THexaIcons = typeof import('@hexa-ui/icons');

export const useDynamicSidebar = () => {
  const { data, isSuccess } = useSidebarService();
  const [items, setItems] = useState<SidebarItem[]>([]);

  useEffect(() => {
    if (isSuccess) {
      const updatedData = data.map((item) => {
        const Icon = icons[item.icon] ?? icons.Menu;

        return {
          ...item,
          icon: () => createElement(Icon),
        };
      });

      setItems(updatedData);
    }
  }, [isSuccess, data]);

  useSidebar({
    items,
    utils: [],
  });
};
