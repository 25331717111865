import { useSegmentAnalytics } from '@hooks';
import { EnvConfig, GlobalProvider } from '@providers';
import { Router } from '@routes';

export default function App(props: EnvConfig) {
  useSegmentAnalytics(props.SEGMENT_KEY);

  return (
    <GlobalProvider {...props}>
      <Router />
    </GlobalProvider>
  );
}
