import { EnvConfig, EnvProvider, I18nProvider, QueryProvider, ThemeProvider } from '@providers';
import { PropsWithChildren } from 'react';

export function GlobalProvider(props: PropsWithChildren<EnvConfig>) {
  return (
    <QueryProvider>
      <EnvProvider env={props}>
        <ThemeProvider>
          <I18nProvider>{props.children}</I18nProvider>
        </ThemeProvider>
      </EnvProvider>
    </QueryProvider>
  );
}
