/* eslint-disable react-hooks/rules-of-hooks */
import { useApi } from '@api';
import { useAuthenticationService } from 'admin-portal-shared-services';
import { ISpreeadsheetRequestResponse } from './forecastSpreadsheetTemplate.types';

const FEATURE = 'FORECAST';
const QUERY_KEY_TEMPLATE = 'forecastSpreadsheetTemplate';
const URL = 'api/link-collab-forecast-bff/spreadsheet/generator';

export const ForecastSpreadsheetTemplate = async (): Promise<
  ISpreeadsheetRequestResponse | undefined
> => {
  const { post } = useApi();
  const authentication = useAuthenticationService();

  const { user_country: country, language } = authentication.getUserCountryAndLanguage();

  try {
    const response = await post({
      url: URL,
      config: {
        headers: {
          country,
          feature: FEATURE,
          language,
          requestTraceId: `${QUERY_KEY_TEMPLATE}_${FEATURE}`,
        },
      },
    });

    return response.data;
  } catch (error) {
    return;
  }
};
